.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.product-select {
    cursor: pointer;
}

.img-responsive {
    max-width: 80px;
}

.product-select:after {
    background-color: lightgray;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.product-select:hover:after {
    /* background: lightgray; */
    opacity: .5;
}

h3 {
    text-align: center;
}

.hreni:hover {
    transition: 0.5s;
    filter: grayscale(0%);
    border-left: 2px solid #7ba1d7;
    border-right: 2px solid #7ba1d7;
}

.hreni:active {
    transition: 1s;
    filter: saturate(300%);
}

.hreni {
    transition: 1s;
    filter: grayscale(100%);
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    box-sizing: border-box;
}

img~button {
    display: block;
    margin: auto;
    width: 150px;
    font-variant: small-caps;
    font-size: 15px;
    background-color: lightseagreen;
    outline: none;
}

* {
    box-sizing: border-box;
    /* border-radius: 10px; */
}

canvas {
    box-sizing: border-box;
    position: relative;
    width: 100%;
}

@keyframes gradientBG {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

html,
body {
    margin: 0;
    width: 100%;
    height: 100vh;
    font-family: "Exo", sans-serif;
    /* color: #fff; */
    /* background: linear-gradient(-45deg, #ee7752, #d58fcd, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradientBG 15s ease infinite; */
}

.cart_box {
    width: 100%;
    height: 100vh;
    background: linear-gradient(-45deg, #23d5ab, #23a6d5, #d58fcd, #ee7752);
    background-size: 400% 400%;
    animation: gradientBG 15s ease infinite;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

#add_to_cart {
    background-color: #a7d339;
}

canvas {
    border-bottom: 2px solid #7ba1d7;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.bg_load {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: #EEE;
}

.wrapper {
    /* Size and position */
    font-size: 25px;
    /* 1em */
    width: 8em;
    height: 8em;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-top: -100px;
    margin-left: -100px;
    /* Styles */
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    border: 1em dashed rgba(138, 189, 195, 0.5);
    box-shadow: inset 0 0 2em rgba(255, 255, 255, 0.3), 0 0 0 0.7em rgba(255, 255, 255, 0.3);
    animation: rota 3.5s linear infinite;
    /* Font styles */
    font-family: 'Racing Sans One', sans-serif;
    color: #444;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 .04em rgba(82, 216, 120, 0.9);
    line-height: 6em;
}

.wrapper:before,
.wrapper:after {
    content: "";
    position: absolute;
    z-index: -1;
    border-radius: inherit;
    box-shadow: inset 0 0 2em rgba(223, 68, 68, 0.3);
    border: 1em dashed;
}

.wrapper:before {
    border-color: rgba(27, 191, 210, 0.2);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.wrapper:after {
    border-color: rgba(195, 177, 138, 0.4);
    top: 1em;
    right: 1em;
    bottom: 1em;
    left: 1em;
}

.wrapper .inner {
    width: 100%;
    height: 100%;
    animation: rota 3.5s linear reverse infinite;
}

.wrapper span {
    display: inline-block;
    animation: placeholder 1.5s ease-out infinite;
}

.wrapper span:nth-child(1) {
    animation-name: loading-1;
}

.wrapper span:nth-child(2) {
    animation-name: loading-2;
}

.wrapper span:nth-child(3) {
    animation-name: loading-3;
}

.wrapper span:nth-child(4) {
    animation-name: loading-4;
}

.wrapper span:nth-child(5) {
    animation-name: loading-5;
}

.wrapper span:nth-child(6) {
    animation-name: loading-6;
}

.wrapper span:nth-child(7) {
    animation-name: loading-7;
}

@keyframes rota {
    to {
        transform: rotate(360deg);
    }
}

@keyframes loading-1 {
    14.28% {
        opacity: 0.3;
    }
}

@keyframes loading-2 {
    28.57% {
        opacity: 0.3;
    }
}

@keyframes loading-3 {
    42.86% {
        opacity: 0.3;
    }
}

@keyframes loading-4 {
    57.14% {
        opacity: 0.3;
    }
}

@keyframes loading-5 {
    71.43% {
        opacity: 0.3;
    }
}

@keyframes loading-6 {
    85.71% {
        opacity: 0.3;
    }
}

@keyframes loading-7 {
    100% {
        opacity: 0.3;
    }
}